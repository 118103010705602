import React, { FC } from "react";
import {
	TextField,
	Button,
	Typography,
	Box,
	createStyles,
	Theme,
	makeStyles,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useForm, SubmitHandler } from "react-hook-form";
import AuthLayout from "./AuthLayout";
import AuthHead from "./AuthHead";
import { formRules } from "../formConstants";
import { signUpUser, logoutUser } from "../../../redux/thunks/authThunk";
import { setShowUsernameForm } from "../../../redux/slices/authSlice";
import { RootState } from "../../../redux/rootReducer";

import { useTranslation } from "react-i18next";
type FormValues = { username: string; email: string };

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			width: "100%",
			"& .MuiTextField-root": {
				margin: theme.spacing(1),
			},
		},
	})
);
type FormUsernameProps = {};

const FormSignUp: FC<FormUsernameProps> = () => {
	const { t } = useTranslation();
	const classes = useStyles();

	const dispatch = useDispatch();

	const phoneNumber = useSelector((state: RootState) => state.auth.firebaseUser?.phoneNumber);

	// React-hook-form
	const { handleSubmit, errors, register } = useForm<FormValues>({
		mode: "onChange",
		defaultValues: { username: "", email: "" },
	});

	const handleSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		handleSubmit(handleCreateUserInDB)();
	};

	const handleCreateUserInDB: SubmitHandler<FormValues> = (data) => {
		dispatch(
			signUpUser({
				data: {
					email: data.email.trim(),
					username: data.username.trim(),
				},
				onSuccess: () => {
					dispatch(setShowUsernameForm(false));
				},
			})
		);
	};

	const handleLogout = () => {
		dispatch(logoutUser({}));
	};

	return (
		<AuthLayout>
			<AuthHead title={t("Sign Up to Continue")} />

			<Typography>
				{t("You have logged in as ")}
				{phoneNumber}
			</Typography>
			<form className={classes.form} onSubmit={handleSubmitForm}>
				<TextField
					required
					// @ts-ignore
					inputRef={register(formRules.email)}
					fullWidth
					id='email-text-field-signup-form'
					name='email'
					label={t("Email")}
					variant='outlined'
					error={!!errors.email}
					helperText={errors.email?.message}
					InputLabelProps={{ shrink: true }}
				/>

				<TextField
					required
					// @ts-ignore
					inputRef={register(formRules.username)}
					fullWidth
					id='username-textfill'
					name='username'
					label={t("Username")}
					variant='outlined'
					error={!!errors.username}
					helperText={errors.username?.message}
					InputLabelProps={{ shrink: true }}
				/>

				<Button
					disabled={!!errors.username || !!errors.email}
					type='submit'
					variant='contained'
					color='primary'
					fullWidth
					size='large'
				>
					{t("SIGN UP")}
				</Button>
			</form>

			<Box paddingY={2}>
				<Button onClick={handleLogout}>
					<strong>{t("Logout")}</strong>
				</Button>
			</Box>
		</AuthLayout>
	);
};

export default FormSignUp;
