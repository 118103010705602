import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
	IServices,
	dbCount,
	dbOneRoomStat,
	IPayments,
	Comments,
	RoomListData,
	Loading,
} from "../../types";
import { PaymentOptions } from "../../constants/PaymentOptions";
import { AppServices } from "../../constants/AppServices";
import _ from "lodash";

export const SortLabelMap: { label: string; key: ISort }[] = [
	{ label: "Max", key: "max" },
	{ label: "Member", key: "membersLength" },
	{ label: "Date", key: "createdAt" },
];

export const SortOptions: ISort[] = ["max", "membersLength", "createdAt"];
export type ISort = "max" | "membersLength" | "createdAt";

export interface IApp {
	sumAvaiable: number;
	queueStat?: Record<IServices, dbCount & { lastUpdate: string }>;
	roomStat?: Record<IServices, dbOneRoomStat & { lastUpdate: string }>;
	RoomList: { loading: Loading; data: RoomListData[] };
	hasFetchRoomList: boolean;
	hasClosedFamilyhint: boolean;
	joinPaperState: {
		filter: Record<IPayments, boolean>;
		isHostPaper: boolean;
		open: boolean;
		select: IServices | null;
	};
	comments: Comments;
	roomListFilter: {
		sortMap: Record<ISort, boolean>;
		serviceMap: Record<IServices, boolean>;
		paymentsMap: Record<IPayments, boolean>;
	};
}

const initialState: IApp = {
	sumAvaiable: 0,
	hasFetchRoomList: false,
	hasClosedFamilyhint: false,
	joinPaperState: {
		filter: Object.fromEntries(PaymentOptions.map((key) => [key, true])) as Record<
			IPayments,
			boolean
		>,
		isHostPaper: false,
		open: false,
		select: null,
		// open: true,
		// select: "Netflix",
	},
	comments: [],
	roomListFilter: {
		paymentsMap: Object.fromEntries(PaymentOptions.map((key) => [key, true])) as Record<
			IPayments,
			boolean
		>,
		serviceMap: Object.fromEntries(AppServices.map((key) => [key, true])) as Record<
			IServices,
			boolean
		>,
		sortMap: Object.fromEntries(SortOptions.map((key) => [key, key !== "createdAt"])) as Record<
			ISort,
			boolean
		>,
	},

	RoomList: { data: [], loading: "idle" },
};

// {
// 	roomID: "1",
// 	service: "Netflix",
// 	host: "JenJen",
// 	payments: ["Bank_Transfer", "FPS", "PayMe"],
// 	max: 4,
// 	membersLength: 3,
// 	createdAt: new Date(
// 		"Fri Sep 10 2020 16:45:11 GMT+0800 (Hong Kong Standard Time)"
// 	).getTime(),
// },
// {
// 	roomID: "2",
// 	service: "iCloud",
// 	host: "Andy Sun",
// 	payments: ["Bank_Transfer", "FPS"],
// 	max: 6,
// 	membersLength: 3,
// 	createdAt: new Date(
// 		"Fri Sep 10 2020 16:45:11 GMT+0800 (Hong Kong Standard Time)"
// 	).getTime(),
// },
// {
// 	roomID: "3",
// 	service: "Netflix",
// 	host: "Helen",
// 	payments: ["Bank_Transfer", "PayMe"],
// 	max: 3,
// 	membersLength: 1,
// 	createdAt: new Date(
// 		"Fri Sep 10 2020 16:45:11 GMT+0800 (Hong Kong Standard Time)"
// 	).getTime(),
// },
// {
// 	roomID: "4",
// 	service: "Nintendo Switch",
// 	host: "Vivian",
// 	payments: ["FPS", "PayMe"],
// 	max: 3,
// 	membersLength: 2,
// 	createdAt: new Date(
// 		"Fri Sep 25 2020 16:45:11 GMT+0800 (Hong Kong Standard Time)"
// 	).getTime(),
// },
// {
// 	roomID: "5",
// 	service: "Nintendo Switch",
// 	host: "Koby",
// 	payments: ["FPS"],
// 	max: 3,
// 	membersLength: 2,
// 	createdAt: new Date(
// 		"Fri Sep 25 2020 16:45:11 GMT+0800 (Hong Kong Standard Time)"
// 	).getTime(),
// },
// {
// 	roomID: "6",
// 	service: "Nintendo Switch",
// 	host: "Angus",
// 	payments: ["PayMe"],
// 	max: 3,
// 	membersLength: 2,
// 	createdAt: new Date(
// 		"Fri Sep 25 2020 16:45:11 GMT+0800 (Hong Kong Standard Time)"
// 	).getTime(),
// },
// {
// 	roomID: "7",
// 	service: "Nintendo Switch",
// 	host: "Soren",
// 	payments: ["Bank_Transfer"],
// 	max: 3,
// 	membersLength: 2,
// 	createdAt: new Date(
// 		"Fri Sep 25 2020 16:45:11 GMT+0800 (Hong Kong Standard Time)"
// 	).getTime(),
// },

const appSlice = createSlice({
	name: "app",
	initialState: initialState,
	reducers: {
		fetchAppStat(
			app,
			action: PayloadAction<{
				queueStat: IApp["queueStat"];
				roomStat: IApp["roomStat"];
			}>
		) {
			app.queueStat = action.payload.queueStat;
			app.roomStat = action.payload.roomStat;

			if (action.payload.roomStat) {
				app.sumAvaiable = Object.entries(action.payload.roomStat)
					.map(([k, v]) => v.available || 0)
					.reduce(
						(accumulator, currentValue) =>
							(accumulator as number) + (currentValue as number)
					) as number;
			}
		},
		setHasClosedFamilyhint(app) {
			app.hasClosedFamilyhint = true;
		},

		selectAll(app) {
			app.joinPaperState.filter = Object.fromEntries(
				PaymentOptions.map((key) => [key, true])
			) as Record<IPayments, boolean>;
		},

		deselectAll(app) {
			app.joinPaperState.filter = Object.fromEntries(
				PaymentOptions.map((key) => [key, false])
			) as Record<IPayments, boolean>;
		},
		setFilter(app, action: PayloadAction<{ key: IPayments; value: boolean }>) {
			const { key, value } = action.payload;
			app.joinPaperState.filter[key] = value;
		},

		setIsHostPaper(app, action: PayloadAction<boolean>) {
			app.joinPaperState.isHostPaper = action.payload;
		},

		setSelectPaper(app, action: PayloadAction<{ open: boolean; select: IServices }>) {
			app.joinPaperState.open = action.payload.open;
			app.joinPaperState.select = action.payload.select;
		},

		setClosePaper(app) {
			app.joinPaperState.open = false;
		},

		resetJoinPaperState(app) {
			app.joinPaperState = initialState.joinPaperState;
		},

		fetchComments(app, action: PayloadAction<Comments>) {
			app.comments = [...app.comments, ...action.payload];
		},

		clearComments(app) {
			app.comments = [];
		},

		toggleRoomListSort(app, action: PayloadAction<keyof IApp["roomListFilter"]["sortMap"]>) {
			const key = action.payload;
			app.roomListFilter["sortMap"][key] = !app.roomListFilter["sortMap"][key];

			app.RoomList.data = _.orderBy(
				app.RoomList.data,
				[key],
				[app.roomListFilter.sortMap[key] ? "asc" : "desc"]
			);
		},

		toggleRoomListPayment(
			app,
			action: PayloadAction<keyof IApp["roomListFilter"]["paymentsMap"]>
		) {
			const key = action.payload;
			app.roomListFilter["paymentsMap"][key] = !app.roomListFilter["paymentsMap"][key];
		},
		toggleRoomListService(
			app,
			action: PayloadAction<{
				key: keyof IApp["roomListFilter"]["serviceMap"];
				value: boolean;
			}>
		) {
			const { key, value } = action.payload;
			app.roomListFilter["serviceMap"][key] = value;
		},

		fetchRoomList(app, action: PayloadAction<RoomListData[]>) {
			app.RoomList.data = [...app.RoomList.data, ...action.payload];
		},

		initialFetchRoomList(app, action: PayloadAction<RoomListData[]>) {
			app.hasFetchRoomList = true;
			app.RoomList.data = action.payload;
		},

		setRoomListLoading(app, action: PayloadAction<Loading>) {
			app.RoomList.loading = action.payload;
		},
	},
});

export const {
	fetchAppStat,
	setHasClosedFamilyhint,
	deselectAll,
	selectAll,
	setFilter,
	setIsHostPaper,
	setSelectPaper,
	setClosePaper,
	resetJoinPaperState,
	clearComments,
	fetchComments,
	toggleRoomListPayment,
	toggleRoomListService,
	toggleRoomListSort,
	fetchRoomList,
	initialFetchRoomList,
	setRoomListLoading,
} = appSlice.actions;

export default appSlice.reducer;
