import React, { FC } from 'react';
import { Theme, makeStyles, createStyles, Container, Paper } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import * as colors from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			backgroundColor: colors.blueGrey[900],
		},

		container: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			minHeight: isMobile ? '-webkit-fill-available' : '100vh',
		},

		paper: {
			width: '100%',
			maxWidth: 400,
			padding: theme.spacing(4),
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			justifyContent: 'center',
			borderRadius: 15,
			margin: theme.spacing(4, 0),
		},
	})
);

const AuthLayout: FC = ({ children }) => {
	const classes = useStyles();
	return <Paper className={classes.paper}>{children}</Paper>;
};

export default AuthLayout;

export const AuthPageLayout: FC = ({ children }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<Container className={classes.container}>
				<Paper className={classes.paper}>{children}</Paper>
			</Container>
		</div>
	);
};
