import React, { FC, useState } from "react";
import { makeStyles, Theme, createStyles, useTheme, Typography } from "@material-ui/core";
import AlertDialog from "./Dialogs/AlertDialog";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {},
	})
);

type NewMessageDialogProps = {};

const NewMessageDialog: FC<NewMessageDialogProps> = () => {
	const classes = useStyles();
	const theme = useTheme();

	const [state, setstate] = useState<boolean>(true);

	return (
		<div>
			<AlertDialog
				open={state}
				handleClose={() => {
					setstate(false);
				}}
				title='⚠️Be aware!🚨'
			>
				<Typography variant='body2'>
					We received report to frauds and we have banned it immediately. To minimize
					frauds happens again, please pay extra attention when you meet a new host.
				</Typography>

				<Typography variant='body1' color='error'>
					<strong>{"1. Avoid paying yearly fee at once"}</strong>
				</Typography>
				<Typography variant='body1' color='error'>
					<strong>{"2. Do not pay before you can actually use the service"}</strong>
				</Typography>
				<Typography variant='body1' color='error'>
					<strong>
						{
							"3. Avoid direct contact from host, use group instead, since family should discuss transparently together."
						}
					</strong>
				</Typography>
				<Typography variant='body1' color='error'>
					{"4. Report the host immediately once you find it's suspicious."}
				</Typography>
			</AlertDialog>
		</div>
	);
};

export default NewMessageDialog;
