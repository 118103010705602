import React from "react";
import Main from "./navigation/Main";
import AppRouter from "./navigation/AppRouter";

import useApp from "./hooks/useApp";
import useFireAuth from "./hooks/useFireAuth";
import useUser from "./hooks/useUser";
import useQueueOnce from "./hooks/useQueueOnce";
import { RootState } from "./redux/rootReducer";
import { useSelector, useDispatch } from "react-redux";
import { sum } from "./redux/slices/debugSlice";
import useRooms from "./hooks/useRooms";

import { useTranslation } from "react-i18next";

export default function App() {
	const { i18n } = useTranslation();

	useApp(); // 2 read

	// // required login
	useFireAuth();
	useUser(); // 1 read
	useRooms();
	useQueueOnce(); // 1 * N queues read
	// ////////////////////	// (1 room + members.length) * N services read **live**

	const debug = useSelector((state: RootState) => state.debug);
	const dispatch = useDispatch();
	React.useEffect(() => {
		dispatch(sum());
	}, [debug, dispatch]);

	React.useEffect(() => {
		if (process.env.REACT_APP_STAGE !== "prod") {
			document.title = `${window.location.hostname} + ${process.env.REACT_APP_STAGE}`;
			console.log(window.navigator.language);
		}

		if (window.navigator.language.includes("zh")) {
			i18n.changeLanguage("zh");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Main>
			<AppRouter />
		</Main>
	);
}
