import React from 'react';

import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	useTheme,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { closeDialog } from '../../redux/slices/dialogSlice';

const AlertPaper: React.FC = () => {
	// const classes = useStyles();
	const theme = useTheme();
	const dialog = useSelector((state: RootState) => state.dialog);
	const dispatch = useDispatch();

	const handleClose = () => {
		dispatch(closeDialog());
		if (!!dialog.onCancel) dialog.onCancel();
	};

	const handleConfirm = () => {
		dispatch(closeDialog());
		if (!!dialog.onConfirm) dialog.onConfirm();
	};

	return (
		<Dialog
			PaperProps={{
				style: {
					borderRadius: 15,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				},
			}}
			open={dialog.open}
			onClose={handleClose}
			aria-labelledby={dialog.title}
			aria-describedby={dialog.description}
		>
			<DialogTitle>{dialog.title}</DialogTitle>

			<DialogContent>
				<DialogContentText align='center'>{dialog.description}</DialogContentText>
			</DialogContent>

			<DialogActions style={{ width: '100%' }}>
				<Button
					fullWidth
					className='round-btn'
					onClick={handleClose}
					style={{ color: theme.palette.text.secondary }}
				>
					{dialog.cancelText || 'cancel'}
				</Button>

				<Button fullWidth className='round-btn' onClick={handleConfirm} color='secondary'>
					{dialog.confirmText || 'confirm'}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AlertPaper;
