import { ValidationRules } from "react-hook-form/dist/types/validator";

export const required = { value: true, message: "This is required" };

export const formRules: { [x: string]: ValidationRules } = {
	username: {
		required,
		maxLength: {
			value: 30,
			message: "Max length 30 characters.",
		},
	},
	password: {
		required,
		minLength: {
			value: 8,
			message: "Min length 8 characters.",
		},
		pattern: {
			value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{0,}$/,
			message: "At least one digit, one lowercase character and one uppercase character.",
		},
	},
	email: {
		required,
		pattern: {
			value: /^\w+([\\.-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,3})+$/i,
			message: "This is not a valid email.",
		},
	},
};

export const phoneRule: ValidationRules = {
	required,
	pattern: {
		value: /^[0-9]{8}$/i,
		message: "This is not a valid phone number.",
	},
	maxLength: 8,
	minLength: 8,
};
