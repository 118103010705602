import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { HistRoom, LikeState, IServices, HostHistory } from "../../types";
import { AppServices } from "../../constants/AppServices";
import { LoadingState } from "./loadSlice";

interface IHistory {
	page: number;
	rooms?: HistRoom[];
	records?: HostHistory[];
	filter: Record<IServices, boolean>;
	expandAll: boolean;
	showFilter: boolean;
	state: {
		record: LoadingState;
		room: LoadingState;
	};

	hasFetchRecord: boolean;
	hasFetchRoom: boolean;
}

const initialState: IHistory = {
	// 0 -> Joined Family, 1 -> Hosted Family
	page: 0,
	filter: Object.fromEntries(AppServices.map((key) => [key, true])) as Record<IServices, boolean>,
	expandAll: false,
	showFilter: false,
	state: {
		record: { loading: true, error: false, errorMsg: "" },
		room: { loading: false, error: false, errorMsg: "" },
	},
	hasFetchRecord: false,
	hasFetchRoom: false,
};

const historySlice = createSlice({
	name: "history",
	initialState,
	reducers: {
		resetHistory: () => initialState,

		setPage(history, action: PayloadAction<number>) {
			history.page = action.payload;
		},

		selectAll(history) {
			history.filter = Object.fromEntries(AppServices.map((key) => [key, true])) as Record<
				IServices,
				boolean
			>;
		},

		deselectAll(history) {
			history.filter = Object.fromEntries(AppServices.map((key) => [key, false])) as Record<
				IServices,
				boolean
			>;
		},

		expandAll(history) {
			history.rooms?.forEach((e) => (e.isExpandRoom = true));
		},
		collapseAll(history) {
			history.rooms?.forEach((e) => (e.isExpandRoom = false));
		},
		toggleShowFilter(history) {
			history.showFilter = !history.showFilter;
		},
		setFilter(history, action: PayloadAction<{ key: IServices; value: boolean }>) {
			const { key, value } = action.payload;
			history.filter[key] = value;
		},
		toggleExpandRoom(history, action: PayloadAction<string>) {
			const roomID = action.payload;
			const rooms = history.rooms;
			if (!rooms) return;
			const thisRoom = rooms.filter((e) => e.roomID === roomID)[0];
			thisRoom.isExpandRoom = !thisRoom.isExpandRoom;
		},

		addComment(
			history,
			action: PayloadAction<{ roomID: string; histID: string; isHost: boolean }>
		) {
			const { histID, roomID, isHost } = action.payload;

			if (!isHost) {
				// user is not host, rate (change) host doc
				const thisRecord = history.records?.find((e) => e.histID === histID);

				if (!thisRecord) return;

				thisRecord.isRated = true;
			} else {
				// user is  host, rate (change) user doc

				const thisRecord = history.rooms
					?.find((e) => e.roomID === roomID)
					?.members.find((e) => e.histID === histID);

				if (!thisRecord) return;

				thisRecord.isRated = true;
			}
		},

		pushMemberHistroies(history, action: PayloadAction<HostHistory[]>) {
			if (!history.records) {
				// fetch first
				history.records = action.payload;
				return;
			}

			history.records = [...history.records, ...action.payload];
		},

		pushArchiveRooms(history, action: PayloadAction<HistRoom[]>) {
			if (!history.rooms) {
				// fetch first
				history.rooms = action.payload;
				return;
			}

			history.rooms = [...history.rooms, ...action.payload];
		},

		pushHostHistories(
			history,
			action: PayloadAction<{ members: HistRoom["members"]; roomID: string }>
		) {
			const { members, roomID } = action.payload;

			const thisRoom = history.rooms?.find((e) => e.roomID === roomID);
			if (!thisRoom) return;

			if (!thisRoom.members) {
				// fetch first
				thisRoom.members = members;
				return;
			}

			thisRoom.members = [...thisRoom.members, ...members];
		},

		setLoadingArcMemb(history, action: PayloadAction<{ roomID: string; loading: boolean }>) {
			const { roomID, loading } = action.payload;

			const thisRoom = history.rooms?.find((e) => e.roomID === roomID);
			if (!thisRoom) return;

			thisRoom.isLoading = loading;
		},

		editLikeState(
			history,
			action: PayloadAction<{
				isHost: boolean;
				roomID: string;
				histID: string;
				likeState: LikeState;
			}>
		) {
			const { isHost, roomID, histID, likeState } = action.payload;

			if (!isHost) {
				// user is not host, rate (change) host doc
				const thisRecord = history.records?.find((e) => e.histID === histID);

				if (!thisRecord) return;

				thisRecord.likeState = likeState;
			} else {
				// user is  host, rate (change) user doc

				const thisRecord = history.rooms
					?.find((e) => e.roomID === roomID)
					?.members.find((e) => e.histID === histID);

				if (!thisRecord) return;

				thisRecord.likeState = likeState;
			}
		},

		removeHistory(
			history,
			action: PayloadAction<{ isHost: boolean; roomID: string; histID: string }>
		) {
			const { isHost, roomID, histID } = action.payload;

			if (!isHost) {
				// remove hostHist in record
				const thisRecordIndex = history.records?.findIndex((e) => e.histID === histID);

				if (thisRecordIndex === undefined || thisRecordIndex === -1) return;

				history.records?.splice(thisRecordIndex, 1);
			} else {
				// remove memberHist in Room
				const thisRoom = history.rooms?.find((e) => e.roomID === roomID);
				if (!thisRoom) return;

				const thisRecordIndex = thisRoom.members.findIndex((e) => e.histID === histID);
				if (!thisRecordIndex === undefined || thisRecordIndex === -1) return;

				thisRoom.members.splice(thisRecordIndex, 1);

				// if histCount === 0, remove this room
				if (thisRoom.members.length === 0) {
					const thisRoomIndex = history.rooms?.findIndex((e) => e.roomID === roomID);
					if (thisRoomIndex === undefined || thisRoomIndex === -1) return;

					history.rooms?.splice(thisRoomIndex, 1);
				}
			}
		},

		startLoadingHist(history, action: PayloadAction<"record" | "room">) {
			history.state[action.payload].loading = true;
		},

		stopLoadingHist(history, action: PayloadAction<"record" | "room">) {
			history.state[action.payload].loading = false;
		},

		loadingHistFailed(
			history,
			action: PayloadAction<{ type: "record" | "room"; msg: string }>
		) {
			const { msg, type } = action.payload;

			history.state[type].loading = false;
			history.state[type].error = true;
			history.state[type].errorMsg = msg;
		},

		doneFirstFetch(history, action: PayloadAction<"hasFetchRoom" | "hasFetchRecord">) {
			history[action.payload] = true;
		},
	},
});

export const {
	resetHistory,
	setPage,
	addComment,
	pushMemberHistroies,
	pushArchiveRooms,
	editLikeState,
	collapseAll,
	deselectAll,
	expandAll,
	selectAll,
	toggleShowFilter,
	setFilter,
	toggleExpandRoom,
	loadingHistFailed,
	startLoadingHist,
	stopLoadingHist,
	doneFirstFetch,
	pushHostHistories,
	setLoadingArcMemb,
	removeHistory,
} = historySlice.actions;
export default historySlice.reducer;
