import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { LoadThunk } from "../store";
import { startLoading, stopLoading } from "./loadSlice";
type ISnacks = "error" | "warning" | "info" | "success" | null;
type SnackState = { open: boolean; type: ISnacks; msg: string; direction: "bottom" | "top" };

const initialState: SnackState = {
	open: false,
	type: null,
	msg: "",
	direction: "bottom",
};

const snackSlice = createSlice({
	name: "snack",
	initialState: initialState,
	reducers: {
		showSnack(
			snack,
			action: PayloadAction<{ type: ISnacks; msg: string; direction?: "top" | "bottom" }>
		) {
			const { type, msg, direction } = action.payload;

			snack.open = true;
			snack.type = type;
			snack.msg = msg;
			snack.direction = direction || "bottom";
		},
		closeSnack(snack) {
			snack.open = false;
		},

		successSnack(snack, action: PayloadAction<{ msg: string; direction?: "top" | "bottom" }>) {
			const { msg, direction } = action.payload;

			snack.open = true;
			snack.type = "success";
			snack.msg = msg;
			snack.direction = direction || "bottom";
		},

		errorSnack(snack, action: PayloadAction<{ msg: string; direction?: "top" | "bottom" }>) {
			const { msg, direction } = action.payload;

			snack.open = true;
			snack.type = "error";
			snack.msg = msg;
			snack.direction = direction || "bottom";
		},
	},
});

export const { showSnack, closeSnack, successSnack, errorSnack } = snackSlice.actions;

export default snackSlice.reducer;

export const LoadSnackThunk: LoadThunk = (
	{ errorMsg, onSuccess, onError, onFinish, successMsg, actionName },
	action
) => async (dispatch) => {
	// start loading
	dispatch(startLoading());
	await action(dispatch)
		.then(() => {
			// success
			dispatch(stopLoading());
			dispatch(successSnack({ msg: successMsg || String(actionName + " success") }));

			if (!!onSuccess) onSuccess();
		})
		.catch((error) => {
			// fail
			console.error(error);
			dispatch(stopLoading());
			dispatch(errorSnack({ msg: errorMsg || String(actionName + " failed") }));
			if (!!onError) onError();
		})
		.finally(() => {
			if (!!onFinish) onFinish();
		});
};
