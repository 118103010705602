import React, { FC, Suspense } from "react";
import { makeStyles, Theme, createStyles, CssBaseline, Paper, Toolbar } from "@material-ui/core";

import Loading from "../components/Loading";
import useMobileView from "../hooks/useMobileView";
import StateComponent from "./StateComponent";
import AppNavBar from "./AppNavBar";
import NewMessageDialog from "../components/NewMessageDialog";

const BottomTabs = React.lazy(() => import("./BottomTabs"));

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: "flex",
			flexDirection: "column",
			height: "100%",
		},

		paper: {
			flexGrow: 1,
			padding: theme.spacing(2, 2, 5),
			// background: '#d6e0f0',
		},
	})
);

const Main: FC = ({ children }) => {
	const classes = useStyles();

	const isMobileView = useMobileView();

	return (
		<Suspense fallback={<Loading />}>
			<div className={classes.root}>
				<CssBaseline />
				{/* Loading Modal */}
				<StateComponent />

				<NewMessageDialog />

				<AppNavBar isMobileView={isMobileView} />

				<Paper elevation={0} className={classes.paper}>
					{children}
				</Paper>
				{isMobileView && (
					<>
						<BottomTabs />
						<Toolbar />
					</>
				)}
				{/* {isMobile && isPWA ? <BottomTabs /> : <InstallPWA />} */}
			</div>
		</Suspense>
	);
};

export default Main;
