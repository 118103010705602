import FamatchIO_logo from "../assets/design/famatch-logo.png";
import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { AppRoutes } from "../navigation/AppRoutes";

const scale = 30;
const width = 3454 / scale;
const height = 1181 / scale;

export const FamatchLogo: FC = () => {
	const history = useHistory();
	return (
		<img
			style={{ width, height, cursor: "pointer" }}
			onClick={() => {
				history.push(AppRoutes.HEAD);
			}}
			src={FamatchIO_logo}
			alt='FamatchIO_logo'
		/>
	);
};
