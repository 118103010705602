import React from "react";
import Box from "@material-ui/core/Box/Box";
import { FamatchLogo } from "../../../BrandComponents/FamatchLogo";

const AuthHead: React.FC<{ title: string }> = ({ title }) => {
	return (
		<>
			{/* Logo */}
			<FamatchLogo />

			{/* Title */}
			<Box marginY={2} fontSize='h6.fontSize'>
				{title}
			</Box>
		</>
	);
};

export default AuthHead;
