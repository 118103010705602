import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IServices, IPayments, IFilter, userUsing } from '../../types';

export type Queue = {
	qid: string;
	service: IServices;
	paymentsMap: Record<IPayments, boolean>;
	startAt: string;
	editing: boolean;

	// backup
	prevPaymentsMap: Record<IPayments, boolean> | null;
};

const initialState: Queue[] = [];
// const initialState: Queue[] = [
// 	{
// 		qid: 'jiGzS6SWKWFwT49RcvNC',
// 		editing: false,
// 		paymentsMap: { PayMe: true, Bank_Transfer: true, FPS: true },
// 		prevPaymentsMap: null,
// 		service: 'Spotify',
// 		startAt: 'Wed Aug 19 2020 00:38:56 GMT+0800 (Hong Kong Standard Time)',
// 	},
// ];

const queueSlice = createSlice({
	name: 'queue',
	initialState: initialState,
	reducers: {
		fetchQueues(queues, action: PayloadAction<Queue[]>) {
			action.payload.forEach((queue) => {
				const index = queues.findIndex((e) => e.service === queue.service);
				if (index === -1) {
					// does not have
					queues.push(queue);
				} else {
					// update
					queues[index] = queue;
				}
			});
		},

		resetQueue: () => initialState,

		pushQueue(
			queues,
			action: PayloadAction<{
				qid: string;
				service: IServices;
				paymentsMap: IFilter<IPayments>;
			}>
		) {
			const { service, paymentsMap, qid } = action.payload;
			queues.unshift({
				editing: false,
				paymentsMap,
				prevPaymentsMap: null,
				service,
				startAt: Date().toString(),
				qid,
			});
		},

		removeQueue(queues, action: PayloadAction<string>) {
			const qid = action.payload;
			return queues.filter((q) => q.qid !== qid);
		},

		cleanQueue(queues, action: PayloadAction<Partial<Record<IServices, userUsing>>>) {
			const using = action.payload;

			// filter out services to be removed
			const notQservices: string[] = Object.entries(using)
				.filter(([service, value]) => value?.state !== 'queue')
				.map(([service, value]) => service);

			// filter out
			return queues.filter((q) => !notQservices.includes(q.service));
		},

		enterEditQueue(queues, action: PayloadAction<IServices>) {
			const service = action.payload;

			// ************ cancel previous editing ************
			const CancelQueues = queues
				.filter((e) => e.service !== service && e.editing)
				.map((e) => e.service);

			CancelQueues.forEach((service) => {
				const thisQ = queues.find((e) => e.service === service);
				if (!thisQ || !thisQ.prevPaymentsMap) return;

				thisQ.editing = false;

				// roll back to prev
				thisQ.paymentsMap = thisQ.prevPaymentsMap;

				// clear prev
				thisQ.prevPaymentsMap = null;
			});
			// ************ cancel previous editing ************

			const thisQ = queues.find((e) => e.service === service);
			if (!thisQ) return;

			thisQ.editing = true;

			// backup
			thisQ.prevPaymentsMap = thisQ.paymentsMap;
		},

		cancelEditQueue(queues, action: PayloadAction<IServices>) {
			const service = action.payload;

			const thisQ = queues.find((e) => e.service === service);
			if (!thisQ || !thisQ.prevPaymentsMap) return;

			thisQ.editing = false;

			// roll back to prev
			thisQ.paymentsMap = thisQ.prevPaymentsMap;

			// clear prev
			thisQ.prevPaymentsMap = null;
		},

		confirmEditQueue(queues, action: PayloadAction<string>) {
			const qid = action.payload;
			const thisQ = queues.find((e) => e.qid === qid);
			if (!thisQ) return;

			thisQ.editing = false;

			// clear prev
			thisQ.prevPaymentsMap = null;
		},

		// payment chips
		removeQueuePayment(
			queues,
			action: PayloadAction<{ service: IServices; payment: IPayments }>
		) {
			const { service, payment } = action.payload;
			const thisQ = queues.find((e) => e.service === service);
			if (!thisQ) return;

			thisQ.paymentsMap[payment] = false;
		},

		toggleQueuePayment(
			queues,
			action: PayloadAction<{ service: IServices; payment: IPayments }>
		) {
			const { service, payment } = action.payload;
			const thisQ = queues.find((e) => e.service === service);
			if (!thisQ) return;

			thisQ.paymentsMap[payment] = !thisQ.paymentsMap[payment];
		},
	},
});

export const {
	fetchQueues,
	resetQueue,
	cancelEditQueue,
	confirmEditQueue,
	enterEditQueue,
	removeQueuePayment,
	toggleQueuePayment,
	removeQueue,
	cleanQueue,
	pushQueue,
} = queueSlice.actions;

export default queueSlice.reducer;
