import React, { FC } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

interface AlertDialogProps {
	title?: string;
	open: boolean;
	handleClose: () => void;
}

const AlertDialog: FC<AlertDialogProps> = ({ handleClose, open, children, title }) => {
	return (
		<Dialog
			PaperProps={{ style: { borderRadius: 15 } }}
			open={open}
			onClose={handleClose}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
		>
			<DialogTitle id='alert-dialog-title'>{title || "Hi there 👋"}</DialogTitle>
			<DialogContent>
				<DialogContentText id='alert-dialog-description'>{children}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color='primary' autoFocus>
					Okay
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AlertDialog;
