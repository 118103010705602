import { createMuiTheme } from "@material-ui/core";

const MuiTheme = createMuiTheme({
	overrides: {
		MuiCssBaseline: {
			"@global": {},
		},
		MuiButton: {
			text: {
				textTransform: "none",
			},
			containedPrimary: {
				textTransform: "none",
				"&:hover": {
					backgroundColor: "#ffab63",
				},
				fontWeight: "bold",
			},
		},
		MuiInputBase: {
			input: {
				height: -1,
			},
		},
	},

	typography: {
		// fontFamily: "'Montserrat', sans-serif",
	},

	palette: {
		primary: {
			main: "#ffac00",
			contrastText: "#ffffff",
		},
		secondary: {
			main: "#d92e2b",
		},
		background: {
			default: "#f8f9f9",
			paper: "#f8f9f9",
		},
		text: {
			primary: "#2b2b2b",
		},
	},
});

export default MuiTheme;
