import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { fetchApp } from '../redux/thunks/appThunk';

export default function useApp() {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchApp({}));
	}, [dispatch]);
}
