import { db, FieldValue } from "../configs/firebase";
import { dbUser, dbQueue, IPayments, IServices, dbQueueStat, Profile, IContact } from "../types";
import { Queue } from "../redux/slices/queueSlice";
import { PaymentsToMap, TimestampToString } from "../utils/Functions";

export async function FETCH_QUEUES(userID: string) {
	const queuesRef = db.collection("queues").where("user.uid" as keyof dbQueue, "==", userID);

	const snaps = await queuesRef.get();
	if (snaps.empty) return [];

	const queues = snaps.docs.map((snap) => {
		const dbQueue = snap.data() as dbQueue;
		const { service, createdAt, payments } = dbQueue;

		return {
			qid: snap.id,
			service,
			editing: false,
			paymentsMap: PaymentsToMap(payments),
			startAt: TimestampToString(createdAt),
			prevPaymentsMap: null,
		};
	}) as Queue[];

	return queues;
}

// Add to  Member Queue
// 3W ✅
// // ⚠️ check is done before calling the function, because user state is in the sync (read the user state to check added or not)
export async function ADD_QUEUE(
	service: IServices,
	userID: string,
	userProfile: Profile,
	userContactMap: Record<IContact, string | null>,
	paymentsMap: Record<IPayments, boolean>
) {
	// add member
	const queueRef = db.collection("/queues").doc();
	// update member stat
	const statRef = db.doc(`/stat/queue`);
	// update user state
	const userRef = db.doc(`/users/${userID}`);

	const memberData: dbQueue = {
		payments: Object.entries(paymentsMap)
			.filter(([k, v]) => v)
			.map(([k, v]) => k) as IPayments[],
		service,
		createdAt: FieldValue.serverTimestamp(),
		user: {
			uid: userID,
			contactMap: userContactMap,
			profile: userProfile,
		},
	};

	const countData: Partial<dbQueueStat> = {
		[service]: {
			count: FieldValue.increment(1),
			lastUpdate: FieldValue.serverTimestamp(),
		},
	};

	let userData: Pick<dbUser, "using" | "serviceStat"> = {
		using: { [service]: { id: queueRef.id, role: null, state: "queue" } },
	};

	await db.runTransaction(async (t) => {
		// add member
		t.set(queueRef, memberData);
		// add count
		t.set(statRef, countData, { merge: true });
		// update user
		t.set(userRef, userData, { merge: true });
	});

	return queueRef.id;
}

// 2W 1D ✅
export async function DELETE_QUEUE(qid: string, service: IServices, userID: string) {
	// delete member
	const queueRef = db.doc(`/queues/${qid}`);
	// update member stat
	const statRef = db.doc(`/stat/queue`);
	// update user state
	const userRef = db.doc(`/users/${userID}`);

	const countData: Partial<dbQueueStat> = {
		[service]: {
			count: FieldValue.increment(-1),
			lastUpdate: FieldValue.serverTimestamp(),
		},
	};

	const userData: Pick<dbUser, "using"> = {
		using: { [service]: FieldValue.delete() },
	};

	await db.runTransaction(async (t) => {
		// delete member
		t.delete(queueRef);
		// update count -1
		t.set(statRef, countData, { merge: true });
		// update user
		t.set(userRef, userData, { merge: true });
	});
}

export async function UPDATE_QUEUE(qid: string, paymentsMap: Record<IPayments, boolean>) {
	// update member
	const queueRef = db.doc(`/queues/${qid}`);
	const queueData: Pick<dbQueue, "payments"> = {
		payments: Object.entries(paymentsMap)
			.filter(([k, v]) => v)
			.map(([k, v]) => k) as IPayments[],
	};
	await queueRef.update(queueData);
}
