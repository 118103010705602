import { IAppThunk } from "../store";
import { IServices, IFilter, IPayments, Profile, IContact } from "../../types";
import { LoadSnackThunk } from "../slices/snackSlice";
import { ADD_QUEUE, DELETE_QUEUE, UPDATE_QUEUE, FETCH_QUEUES } from "../../api/queues";
import { confirmEditQueue, removeQueue, pushQueue, fetchQueues } from "../slices/queueSlice";
import { loadThunk } from "../slices/loadSlice";
import { updateUsing } from "../slices/authSlice";
import { fetchApp } from "./appThunk";
import { analytics } from "../../configs/firebase";

export const fetchQueueOnce: IAppThunk<{
	userID: string;
}> = ({ userID, ...rest }) =>
	// no snack
	loadThunk({ ...rest }, async (dispatch) => {
		const queues = await FETCH_QUEUES(userID);
		dispatch(fetchQueues(queues));
	});

// thunk with reducer have different para!! unlike others
export const addQueue: IAppThunk<{
	userID: string;
	service: IServices;
	userProfile: Profile;
	userContactMap: Record<IContact, string | null>;
	paymentsMap: IFilter<IPayments>;
}> = ({ service, paymentsMap, userID, userProfile, userContactMap, ...rest }) =>
	LoadSnackThunk({ ...rest }, async (dispatch) => {
		analytics.logEvent("add_queue", { service });

		const qid = await ADD_QUEUE(service, userID, userProfile, userContactMap, paymentsMap);

		dispatch(pushQueue({ service, qid, paymentsMap }));
		dispatch(updateUsing({ service, state: { id: qid, state: "queue", role: null } }));

		// refresh App Stat
		dispatch(fetchApp({}));
	});

export const deleteQueue: IAppThunk<{
	qid: string;
	userID: string;
	service: IServices;
}> = ({ qid, service, userID, ...rest }) =>
	LoadSnackThunk({ ...rest }, async (dispatch) => {
		analytics.logEvent("delete_queue", { service });

		await DELETE_QUEUE(qid, service, userID);

		dispatch(removeQueue(qid));
		dispatch(updateUsing({ service, state: { id: null, state: null, role: null } }));

		// refresh App Stat
		dispatch(fetchApp({}));
	});

export const updateQueue: IAppThunk<{
	qid: string;
	paymentsMap: IFilter<IPayments>;
	service: IServices;
}> = ({ qid, paymentsMap, service, ...rest }) =>
	LoadSnackThunk({ ...rest }, async (dispatch) => {
		analytics.logEvent("update_queue", { service });

		await UPDATE_QUEUE(qid, paymentsMap);
		dispatch(confirmEditQueue(qid));
	});
