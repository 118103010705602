import { configureStore, Action } from "@reduxjs/toolkit";

import rootReducer, { RootState } from "./rootReducer";
import { ThunkAction } from "redux-thunk";
import { useDispatch } from "react-redux";

const store = configureStore({
	reducer: rootReducer,
});
export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export type ThunkProps = {
	actionName?: string;
	errorMsg?: string;
	successMsg?: string;
	onSuccess?: () => void;
	onError?: () => void;
	onFinish?: () => void;
};

// for thunk
export type IAppThunk<T = {}> = (x: ThunkProps & T) => AppThunk;

// for load wrapper function
export type LoadThunk = (x: ThunkProps, action: (x: AppDispatch) => Promise<void>) => AppThunk;

export type AppDispatch = typeof store.dispatch;

// Export a hook that can be reused to resolve types
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
