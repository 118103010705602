import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUser, IServices, userUsing } from "../../types";

interface IAuth {
	// firebaseUser?: any;
	firebaseUser?: firebase.User;
	user?: IUser;

	showUsernameForm: boolean;
}

const initialState: IAuth = {
	showUsernameForm: false,
};

const authSlice = createSlice({
	name: "auth",
	initialState: initialState,
	reducers: {
		fetchFireAuth(auth, action: PayloadAction<firebase.User>) {
			auth.firebaseUser = action.payload;
		},

		fetchUser(auth, action: PayloadAction<IUser>) {
			auth.user = action.payload;
		},

		updateUsing(auth, action: PayloadAction<{ service: IServices; state: userUsing }>) {
			const { service, state } = action.payload;
			if (!auth.user) return;

			auth.user.using[service] = state;
		},

		// fetchUserComments(auth, action: PayloadAction<IUser["comments"]>) {
		// 	if (!auth.user) return;
		// 	auth.user.comments = [...auth.user.comments, ...action.payload];
		// },

		setShowUsernameForm(auth, action: PayloadAction<boolean>) {
			auth.showUsernameForm = action.payload;
		},

		setIsSubEmail(auth, action: PayloadAction<boolean>) {
			if (!auth.user) return;
			auth.user.isSubEmail = action.payload;
		},

		resetAuth: () => initialState,
	},
});

export const {
	fetchFireAuth,
	fetchUser,
	resetAuth,
	updateUsing,
	setShowUsernameForm,
	setIsSubEmail,
	// fetchUserComments,
} = authSlice.actions;

export default authSlice.reducer;
