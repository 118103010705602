import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IServices, RoomMap, IPayments, userUsing } from "../../types";

const initialState: RoomMap[] = [];

const roomSlice = createSlice({
	name: "room",
	initialState,
	reducers: {
		fetchRooms(rooms, action: PayloadAction<RoomMap[]>) {
			action.payload.forEach((room) => {
				const index = rooms.findIndex((e) => e.service === room.service);
				if (index === -1) {
					// does not have
					rooms.push(room);
				} else {
					// update
					rooms[index] = room;
				}
			});
		},

		fetchOneRoom(rooms, action: PayloadAction<RoomMap>) {
			const room = action.payload;
			const index = rooms.findIndex((e) => e.rid === room.rid);
			if (index === -1) {
				// does not have
				rooms.push(room);
			} else {
				// update
				rooms[index] = room;
			}
		},

		resetRoom: () => initialState,

		removeRoom(rooms, action: PayloadAction<IServices>) {
			const service = action.payload;
			const index = rooms.findIndex((e) => e.service === service);

			if (index !== -1) {
				// has service
				rooms.splice(index, 1);
			}
		},

		cleanRooms(rooms, action: PayloadAction<Partial<Record<IServices, userUsing>>>) {
			const using = action.payload;

			// filter out services to be removed
			const notRoomServices: string[] = Object.entries(using)
				.filter(([service, value]) => value?.state !== "room")
				.map(([service, value]) => service);

			// filter out
			return rooms.filter((room) => !notRoomServices.includes(room.service));
		},

		// enter edit
		enterEditMode(rooms, action: PayloadAction<IServices>) {
			const service = action.payload;

			// ************ cancel previous editing ************
			const CancelRooms = rooms
				.filter((e) => e.service !== service && e.editing)
				.map((e) => e.service);

			CancelRooms.forEach((service) => {
				const thisRoom = rooms.find((e) => e.service === service);
				if (
					!thisRoom ||
					!thisRoom.prevPaymentsMap ||
					!thisRoom.prevMembers ||
					!thisRoom.prevMax ||
					!thisRoom.prevRoomState
				)
					return;

				thisRoom.editing = false;

				// roll back to prev
				thisRoom.paymentsMap = thisRoom.prevPaymentsMap;
				thisRoom.roomState = thisRoom.prevRoomState;
				thisRoom.members = thisRoom.prevMembers;
				thisRoom.max = thisRoom.prevMax;

				// clear prev
				thisRoom.prevPaymentsMap = null;
				thisRoom.prevRoomState = null;
				thisRoom.prevMembers = null;
				thisRoom.prevMax = null;

				// collapse
				thisRoom.expanded = false;
				thisRoom.prevExpand = null;
			});
			// ************ cancel previous editing ************

			const thisRoom = rooms.find((e) => e.service === service);
			if (!thisRoom) return;

			thisRoom.editing = true;

			// smart expand
			thisRoom.prevExpand = thisRoom.expanded;
			thisRoom.expanded = true;

			// backup
			thisRoom.prevPaymentsMap = thisRoom.paymentsMap;
			thisRoom.prevRoomState = thisRoom.roomState;
			thisRoom.prevMembers = thisRoom.members;
			thisRoom.prevMax = thisRoom.max;
		},

		// cancel edit
		cancelEditing(rooms, action: PayloadAction<IServices>) {
			const service = action.payload;

			const thisRoom = rooms.find((e) => e.service === service);
			if (
				!thisRoom ||
				!thisRoom.prevPaymentsMap ||
				!thisRoom.prevMembers ||
				!thisRoom.prevMax ||
				!thisRoom.prevRoomState
			)
				return;

			thisRoom.editing = false;

			// roll back to prev
			thisRoom.paymentsMap = thisRoom.prevPaymentsMap;
			thisRoom.roomState = thisRoom.prevRoomState;
			thisRoom.members = thisRoom.prevMembers;
			thisRoom.max = thisRoom.prevMax;

			// clear prev
			thisRoom.prevPaymentsMap = null;
			thisRoom.prevRoomState = null;
			thisRoom.prevMembers = null;
			thisRoom.prevMax = null;

			// smart collaspe
			if (!thisRoom.prevExpand) {
				// if prev not expand, collaspe
				thisRoom.expanded = false;
				thisRoom.prevExpand = null;
			}
		},

		// confirm host edit
		confirmHostEdit(rooms, action: PayloadAction<IServices>) {
			const service = action.payload;
			const thisRoom = rooms.find((e) => e.service === service);
			if (!thisRoom) return;

			thisRoom.editing = false;

			// clear prev
			thisRoom.prevPaymentsMap = null;
			thisRoom.prevRoomState = null;
			thisRoom.prevMembers = null;
			thisRoom.prevMax = null;

			// smart collaspe
			if (!thisRoom.prevExpand) {
				// if prev not expand, collaspe
				thisRoom.expanded = false;
				thisRoom.prevExpand = null;
			}
		},

		// expand
		toggleExpand(rooms, action: PayloadAction<IServices>) {
			const service = action.payload;
			const thisRoom = rooms.find((e) => e.service === service);
			if (!thisRoom) return;

			thisRoom.expanded = !thisRoom.expanded;
		},
		removeMemberByUid(room, action: PayloadAction<{ service: IServices; uid: string }>) {
			const { service, uid } = action.payload;
			const thisRoom = room.find((e) => e.service === service);
			if (!thisRoom) return;

			const members = thisRoom.members;
			const removeIndex = members.findIndex((e) => e.user.uid === uid);

			if (thisRoom.prevRoomState === "full") {
				thisRoom.roomState = "private";
			}

			members.splice(removeIndex, 1);
		},
		// payment chips
		removePayment(room, action: PayloadAction<{ service: IServices; payment: IPayments }>) {
			const { service, payment } = action.payload;
			const thisRoom = room.find((e) => e.service === service);
			if (!thisRoom) return;

			thisRoom.paymentsMap[payment] = false;
		},

		togglePayment(room, action: PayloadAction<{ service: IServices; payment: IPayments }>) {
			const { service, payment } = action.payload;
			const thisRoom = room.find((e) => e.service === service);
			if (!thisRoom) return;

			thisRoom.paymentsMap[payment] = !thisRoom.paymentsMap[payment];
		},

		// change room Max
		changeRoomMax(room, action: PayloadAction<{ service: IServices; max: number }>) {
			const { service, max } = action.payload;
			const thisRoom = room.find((e) => e.service === service);
			if (!thisRoom) return;
			const members = thisRoom.members;

			if (members.length === max) {
				thisRoom.roomState = "full";
			} else if (members.length < max && thisRoom.roomState !== "available") {
				thisRoom.roomState = "private";
			}

			thisRoom.max = max;
		},
	},
});

export const {
	enterEditMode,
	cancelEditing,
	confirmHostEdit,
	toggleExpand,
	removePayment,
	togglePayment,
	changeRoomMax,
	resetRoom,
	fetchRooms,
	removeMemberByUid,
	removeRoom,
	fetchOneRoom,
	cleanRooms,
} = roomSlice.actions;
export default roomSlice.reducer;
