import React, { FC } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Button from "@material-ui/core/Button";
import { Badge } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import UserPoper from "../components/NavBars/UserPoper";
import { AppRoutes } from "./AppRoutes";
import { FamatchLogo } from "../BrandComponents/FamatchLogo";

import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			zIndex: theme.zIndex.appBar + 1,
		},
		desktopLogoDiv: { flexGrow: 1, marginLeft: theme.spacing(2) },

		appBar: { backgroundColor: theme.palette.background.default, paddingTop: theme.spacing(2) },

		navButtons: {
			flexGrow: 1,
			"&>*": {
				margin: theme.spacing(0, 2),
			},
		},

		center: {
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
		},
	})
);

const ConditonalStrongTag: FC<{ conditon: boolean }> = ({ conditon, children }) =>
	conditon ? <strong>{children}</strong> : <>{children}</>;

const AppNavBar: FC<{ isMobileView: boolean }> = ({ isMobileView }) => {
	const { t } = useTranslation();
	const classes = useStyles();

	const history = useHistory();
	const location = useLocation();

	const historyPush = (route: string) => {
		history.push(route);

		window.scrollTo(0, 0);
	};

	return (
		<div className={classes.root}>
			<AppBar position='static' className={classes.appBar} color='transparent' elevation={0}>
				{isMobileView ? (
					<Toolbar className={classes.center}>
						<FamatchLogo />
						{process.env.REACT_APP_STAGE === "dev" ? (
							<p>
								<strong>FIREBASE-{process.env.REACT_APP_STAGE}-ENV</strong>
							</p>
						) : (
							<></>
						)}
					</Toolbar>
				) : (
					<Toolbar>
						<div className={classes.desktopLogoDiv}>
							<FamatchLogo />
							{process.env.REACT_APP_STAGE === "dev" ? (
								<p>
									<strong>FIREBASE-{process.env.REACT_APP_STAGE}-ENV</strong>
								</p>
							) : (
								<></>
							)}
						</div>
						{/* routing */}
						<span className={classes.navButtons}>
							<Button
								onClick={() => historyPush(AppRoutes.HOME)}
								disableRipple
								color='inherit'
							>
								<ConditonalStrongTag
									conditon={location.pathname === AppRoutes.HOME}
								>
									{t("Home")}
								</ConditonalStrongTag>
							</Button>
							<Badge color='primary'>
								<Button
									onClick={() => historyPush(AppRoutes.FAMILY)}
									disableRipple
									color='inherit'
								>
									<ConditonalStrongTag
										conditon={location.pathname === AppRoutes.FAMILY}
									>
										{t("Family")}
									</ConditonalStrongTag>
								</Button>
							</Badge>
							<Button
								onClick={() => historyPush(AppRoutes.HISTORY)}
								disableRipple
								color='inherit'
							>
								<ConditonalStrongTag
									conditon={location.pathname === AppRoutes.HISTORY}
								>
									{t("History")}
								</ConditonalStrongTag>
							</Button>
							<Button
								onClick={() => historyPush(AppRoutes.SETTINGS)}
								disableRipple
								color='inherit'
							>
								<ConditonalStrongTag
									conditon={location.pathname === AppRoutes.SETTINGS}
								>
									{t("Settings")}
								</ConditonalStrongTag>
							</Button>
						</span>

						{process.env.NODE_ENV === "development" && (
							<Button
								onClick={() => {
									history.push(AppRoutes.DEBUG);
								}}
							>
								GOTO DEBUG
							</Button>
						)}

						<UserPoper />
					</Toolbar>
				)}
			</AppBar>
		</div>
	);
};

export default AppNavBar;
