import { combineReducers } from "@reduxjs/toolkit";
import authSliceReducer from "./slices/authSlice";
import debugSliceReducer from "./slices/debugSlice";
import loadSliceReducer from "./slices/loadSlice";
import roomSliceReducer from "./slices/roomSlice";
import snackSliceReducer from "./slices/snackSlice";
import dialogSliceReducer from "./slices/dialogSlice";
import historySliceReducer from "./slices/historySlice";
import queueSliceReducer from "./slices/queueSlice";
import appSliceReducer from "./slices/appSlice";

const rootReducer = combineReducers({
	app: appSliceReducer,
	auth: authSliceReducer,
	room: roomSliceReducer,
	queue: queueSliceReducer,
	history: historySliceReducer,
	load: loadSliceReducer,
	snack: snackSliceReducer,
	debug: debugSliceReducer,
	dialog: dialogSliceReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
