import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
	en: {
		translation: {
			commentTimeLimitHint: " * You have {{daysLeft}} {{dayz}} to rate the {{role}}.",
			subemailNoti: " {{sub}} email notification",
			removeMember: 'Remove "{{member}}"',
			removeMemberMsg: 'This will remove "{{member}}" from the room.',

			fromTo: "from {{from}} to {{to}}",
			duration: "duration {{duration}}",

			joinTime: "{{action}} at {{time}}",

			contactDialogTitle: 'Contact "{{member}}"',

			contactDialogDes: `I understand I'm leaving Famatch.io to contact "{{member}}" through Whatsapp.`,

			cancelQueueMsg: "Are you sure you want to cancel the {{service}} queue?",

			searchTotal: "Total: {{sumAvaiable}} Results: {{result}}",

			addQueue: "Add",
			addRoom: "Create",

			// If you encounter any problem or need any help, please email {{link}}
			slogan: `
			Meet
			<0 />
			Your
			<0 />
			New
			<0 />
			Family
			<0 />
			Here!`,
			helpMsg:
				"If you encounter any problem or need any help, please email <0>famatch.io@gmail.com</0>",

			phoneNumberUsage:
				'Your phone number will only be disclosed to your<0>"family members"</0> for your internal communication.<1></1>To prevent scammer ragging on the website, only phone authenticated users can join the matching/searching. However, we know this does not promise the user would not scam you, so please be aware of the risk and <2></2> is not liable for any loss or damge from this.',

			aboutUs: `<1/> is a platform to meet new friends. We hope every user could enjoy <0>a lowest price on subscription services.</0>`,

			verifyEmailMsg: `
			Open your <0>mailbox</0> to check a verification email. You'll need to
			verify your account to continue.`,

			reportHostMsg: 'Are you sure you want to report "{{name}}"?',
		},
	},
	zh: {
		translation: {
			subemailNoti: "已{{sub}}電郵通知",
			commentTimeLimitHint: " * 尚餘{{daysLeft}}日評分{{role}}",
			fromTo: "由 {{from}} 至 {{to}}",
			duration: "維持了 {{duration}}",
			joinTime: "於{{time}}{{action}}",

			// remove member
			removeMember: "剔除「{{member}}」",
			removeMemberMsg: " 此舉將會剔除「{{member}}」出家庭",

			// contact dialog
			contactDialogTitle: "聯絡「{{member}}」",
			contactDialogDes: "我清楚明白我正在離開Famatch.io後與「{{member}}」於{{contact}}聯絡",

			cancelQueueMsg: "確定要離開{{service}}的隊伍嗎？",

			searchTotal: "總數: {{sumAvaiable}} 結果: {{result}}",

			"PAY LESS PLAY MORE": "付更少  玩更多",
			"Sign In to Continue": "請先登入",
			"Sign Up to Continue": "請先註冊",
			"SIGN UP": "註冊",
			"No family yet": "尚未有家庭",
			"Join one or start your own family now!": "參加或者建立一個！",

			Language: "語言",
			Subscribe: "訂閱",
			Unsubscribe: "取消訂閱",
			Logout: "登出",
			Login: "登入",
			Home: "主頁",
			Family: "家庭",
			History: "紀錄",
			Settings: "設定",
			Filters: "進階搜索",
			host: "家庭發起人",
			Host: "家庭發起人",
			member: "家庭成員",

			// sign up
			Username: "用戶名稱",
			Email: "電郵",
			"You have logged in as ": "你登入為",

			"Verify in mailbox to continiue": "請先驗證電郵",
			Verified: "已驗證",
			"Resend verification email": "重新發送驗證電郵",
			verifyEmailMsg: "請先驗證<0>電子郵箱</0>裡的驗證電郵，你必須驗證電郵才可繼續操作。",

			"Joined Family": "加入家庭",
			"Hosted Family": "建立家庭",
			"Select All": "全選",
			"Deselect All": "反選",
			All: "全選",
			None: "反選",

			joined: "加入",
			created: "建立",

			"Delete history": "刪除紀錄",
			"This cannot be recovered.": "此舉無法回溯。",
			"Add a comment": "發表偉論",
			"Expand to contact members": "展開以聯絡家庭成員",
			"This room is opening for match now": "此家庭現正招募中",

			// edit
			"The room will be available to new members joining.": "此家庭將會公開招募",
			"Open the room": "公開家庭",
			Open: "公開家庭",

			"Only family members of this room can access it.": "此家庭將會上鎖",
			"Lock the room": "鎖上家庭",
			Lock: "鎖上家庭",

			"Keep Open": "保持公開",

			// payment error msg
			"At least 1 payment method": "最少一款付款方法",

			// delete room
			"Delete family": "解散家庭",
			"Dismiss family": "解散家庭",
			"Are you sure you want to dismiss the family?": "你確定要解散家庭嗎?",

			"Leave family": "離開家庭",
			"Are you sure you want to leave the family?": "你確定要離開家庭嗎?",

			"Leave queue": "離開隊伍",

			// queue msg
			"Come back later!": "稍後再回來!",
			"We'll notify you by email": "配對成功後會立刻電郵通知你",

			// join paper dialog
			"Find Host": "加入隊伍",
			"Add as member to find host": "以成員身分尋找房主",
			addQueue: "加入隊伍",
			"ADD QUEUE": "加入隊伍",

			"CERATE ROOM": "建立家庭",
			"Add as host to find member": "以發起人身分尋找成員",
			"Find Members": "建立家庭",
			addRoom: "建立家庭",

			"Payments Preference": "付款方式偏好",

			"How many members are you looking for?": "你要搵幾多個人？",
			"Select the number of members you need.": "揀左人數先",

			"Get Started": "立即開始",
			"Start Now": "立即開始",

			"No history yet": "尚未有紀錄",
			"History will show after you left or closed a room.": "你離開或解散家庭後會紀錄在此",
			"Begin with one simple step": "只需輕鬆一Click",
			"No such user!": "沒有此用戶",
			"This user does not exist yet.": "此用戶尚未存在",
			"No opening rooms now": "沒有可供參加的家庭",
			"There are no available rooms not, create one or queue to wait!":
				"目前沒有可供參加的家庭，請先建立會加入隊伍。",
			"Who are we?": "我們是誰？",
			"Why would you need my phone number?": "我的電話號碼會如何使用？",
			"Terms of Service": "服務條款",
			"Privacy policy": "私隱政策",
			"Know more": "了解更多",

			// If you encounter any problem or need any help, please email {{link}}
			helpMsg: "如果你需要任何幫助，請於<0>famatch.io@gmail.com</0>電郵我們",
			phoneNumberUsage:
				"你的電話號碼將會用於<0>「家庭成員」</0>的內部溝通。<1></1>為了防止不法之徒濫用，只有電話驗證了的用戶能夠建立家庭/加入隊伍。我們知道這並不能提供足夠的保障，所以請注意<2></2>不會為家庭糾紛負責。",
			aboutUs:
				"<1/> 是一個旨在撮合用家的平台。我們希望每位用家能以<0>最優惠價錢享用各種訂閱服務。</0>",
			slogan: `
			唔洗再捱
			<0 />
			貴Plan~`,

			Help: "需要幫助",
			Understand: "明白",
			Cancel: "取消",
			Delete: "刪除",
			Confirm: "確定",
			AVAILABLE: "招募中",
			PRIVATE: "私人",
			FULL: "已滿",
			Close: "關閉",
			Done: "完成",
			Edit: "修改",
			COMMENT: "留言",
			Remove: "剔除",
			Leave: "離開",
			Stay: "逗留",
			Keep: "保持",
			Dismiss: "解散",

			vacancy: "家庭空缺",
			queue: "輪候人數",

			// why so stupid ???
			Rooms: "家庭",
			room: "家庭",

			likes: "讚好",
			dislikes: "差評",
			Comments: "評價",

			Max: "上限",
			Member: "成員人數",
			Date: "日期",
			year: "年",
			month: "月",

			"lowest at": "低至",

			people: "人",

			FPS: "轉數快(FPS)",
			"Bank Transfer": "銀行過數",

			Me: "我",

			Report: "檢舉",
			"Report Host": "檢舉發起人",
			reportHostMsg: "你確定要檢舉「{{name}}」嗎？",

			"Successful match": "成功配對",
		},
	},
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: "en",

		keySeparator: false, // we do not use keys in form messages.welcome

		interpolation: {
			escapeValue: false, // react already safes from xss
		},
	});

export default i18n;
