import * as firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/functions";
import "firebase/analytics";
import "firebase/performance";

import { appUrl } from "./appUrl";

const dev = {
	apiKey: process.env.REACT_APP_DEV_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_DEV_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DEV_FIREBASE_DATABASE_URL,
	projectId: process.env.REACT_APP_DEV__FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_DEV_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_DEV__FIREBASE_MSG_SENDER_ID,
	appId: process.env.REACT_APP_DEV_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_DEV_FIREBASE_MEASUREMENT_ID,
};

const prod = {
	apiKey: process.env.REACT_APP_PROD_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_PROD_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_PROD_FIREBASE_DATABASE_URL,
	projectId: process.env.REACT_APP_PROD__FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_PROD_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_PROD__FIREBASE_MSG_SENDER_ID,
	appId: process.env.REACT_APP_PROD_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_PROD_FIREBASE_MEASUREMENT_ID,
};

export const firebaseConfig = process.env.REACT_APP_STAGE === "dev" ? dev : prod;

export const firebaseApp = firebase.initializeApp(firebaseConfig);

if (window.location.hostname === "localhost" && process.env.REACT_APP_STAGE === "dev") {
	console.log("testing locally -- hitting local functions and firestore emulators");

	firebase.functions().useFunctionsEmulator("http://localhost:5001");
	firebase.firestore().settings({
		host: "localhost:8080",
		ssl: false,
	});
}

export const fireAuth = firebase.auth(firebaseApp);

export const cloudFunction =
	window.location.hostname === "localhost" && process.env.REACT_APP_STAGE === "dev"
		? firebase.functions()
		: firebaseApp.functions("asia-east2");
export const db = firebase.firestore(firebaseApp);

firebase.performance();
export const analytics = firebase.analytics(firebaseApp);

if (process.env.NODE_ENV !== "production") {
	analytics.setAnalyticsCollectionEnabled(false);
}

export const FieldValue = firebase.firestore.FieldValue;
export const Timestamp = firebase.firestore.Timestamp;

export const uiConfig: firebaseui.auth.Config = {
	signInSuccessUrl: appUrl(), //This URL is used to return to that page when we got success response for phone authentication.

	signInOptions: [
		{
			provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
			recaptchaParameters: {
				type: "image", // 'audio'
				size: "normal", // 'invisible' or 'compact'
				badge: "bottomleft", //' bottomright' or 'inline' applies to invisible.
			},
			defaultCountry: "HK", // Set default country to the United Kingdom (+44).
			whitelistedCountries: ["HK", "+852"],
		},
	],
	privacyPolicyUrl: appUrl() + "settings",
	tosUrl: appUrl(),
};
