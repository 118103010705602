export const AppRoutes = {
	DEBUG: "/debug",

	HEAD: "/",
	HOME: "/home",
	FAMILY: "/family",
	HISTORY: "/history",
	SETTINGS: "/settings",
	PROFILE: "/profile",
	USERS: "/users/:userID",
	TERMS_OF_SERVICE: "/termsofservice",
	PRIVACY_POLICY: "/privacypolicy",
	USER_ID: (userID: string) => "/users/" + userID,
};

// /users/{userID}
