import React, { FC } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import { closeSnack } from '../../redux/slices/snackSlice';

function Alert(props: AlertProps) {
	return <MuiAlert elevation={2} variant='filled' {...props} />;
}

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: '100%',
		margin: theme.spacing(3, 0),
	},
}));

const CustomSnack: FC = () => {
	const classes = useStyles();

	const { msg, open, type, direction } = useSelector((state: RootState) => state.snack);

	const dispatch = useDispatch();

	const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		dispatch(closeSnack());
	};

	return (
		<Snackbar
			anchorOrigin={{ vertical: direction, horizontal: 'center' }}
			className={classes.root}
			autoHideDuration={3000}
			open={open}
			onClose={handleClose}
		>
			<Alert severity={type || 'info'}>{msg}</Alert>
		</Snackbar>
	);
};

export default CustomSnack;
