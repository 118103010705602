import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppThunk } from "../store";
import { loadThunk } from "./loadSlice";
import { delay } from "../../utils/Functions";
import { IServices } from "../../types";
import { AppServices } from "../../constants/AppServices";

interface count {
	snap: number;
	error: number;
	runEffect: number;
}
interface DebugSlice {
	count: number;
	appStat: { read: 0 };
	user: count;
	hist: count;
	rooms: Partial<Record<IServices, count>>;

	testRooms: count;
	mainColor: string;
}

const initialState: DebugSlice = {
	mainColor: "#ff9e13",
	count: 0,
	appStat: { read: 0 },

	user: {
		snap: 0,
		error: 0,
		runEffect: 0,
	},

	hist: {
		snap: 0,
		error: 0,
		runEffect: 0,
	},

	testRooms: {
		snap: 0,
		error: 0,
		runEffect: 0,
	},

	rooms: Object.fromEntries(
		AppServices.map((e) => [
			e,
			{
				snap: 0,
				error: 0,
				runEffect: 0,
			},
		])
	),
};

const debugSlice = createSlice({
	name: "debug",
	initialState: initialState,
	reducers: {
		add_1(debug) {
			if (debug.count === undefined) return;
			debug.count += 1;
		},
		min_1(debug) {
			if (debug.count === undefined) return;
			debug.count -= 1;
		},

		addAppStat(debug) {
			debug.appStat.read += 2;
		},

		addRead(
			debug,
			action: PayloadAction<{
				key: "user" | "hist" | "testRooms";
				type: keyof count;
				number?: number;
			}>
		) {
			const { key, type, number } = action.payload;
			debug[key][type] += number || 1;
		},

		addSnap(debug, action: PayloadAction<IServices>) {
			const service = action.payload;
			const _this = debug.rooms[service];
			if (!_this) return;

			_this.snap += 1;
		},
		addError(debug, action: PayloadAction<IServices>) {
			const service = action.payload;
			const _this = debug.rooms[service];
			if (!_this) return;

			_this.error += 1;
		},
		addEffect(debug, action: PayloadAction<IServices>) {
			const service = action.payload;
			const _this = debug.rooms[service];
			if (!_this) return;

			_this.runEffect += 1;
		},

		sum(debug) {
			debug.count =
				Object.entries(debug.rooms)
					.map(([key, value]) => value?.snap || 0)
					.reduce((accumulator, currentValue) => accumulator + currentValue) +
				debug.user.snap +
				debug.appStat.read +
				debug.hist.snap;
		},

		changeMainColor(debug, action: PayloadAction<string>) {
			debug.mainColor = action.payload;
		},
	},
});

export const {
	add_1,
	min_1,
	addSnap,
	addError,
	addEffect,
	addAppStat,
	sum,
	addRead,
	changeMainColor,
} = debugSlice.actions;

export default debugSlice.reducer;

export const asyncAdd: IAppThunk = ({ ...rest }) =>
	loadThunk({ ...rest }, async (dispatch) => {
		console.log(`dispatching asyncAdd`);
		await delay(500);
		// throw new Error();
		dispatch(add_1());
	});

export const asyncMinus: IAppThunk = ({ ...rest }) =>
	loadThunk({ ...rest }, async (dispatch) => {
		console.log(`dispatching asyncMinus`);
		await delay(500);
		// throw new Error();
		dispatch(min_1());
	});
