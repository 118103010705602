import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { db } from "../configs/firebase";
import { RootState } from "../redux/rootReducer";
import { dbRooms, RoomMap, Profile } from "../types";
import { fetchOneRoom, resetRoom } from "../redux/slices/roomSlice";
import { addRead } from "../redux/slices/debugSlice";
import { TimestampToString, MembersToFamilyProfile, PaymentsToMap } from "../utils/Functions";

//  N service in use * (1 + members.length) ** live
export default function useRooms() {
	const user = useSelector((state: RootState) => state.auth.user);

	useRoomEffect(user?.uid, user?.profile);
}

// query member room
function useRoomEffect(userID: string | undefined, userProfile: Profile | undefined) {
	const dispatch = useDispatch();

	// member effect
	useEffect(() => {
		if (!userID || !userProfile) return;
		// debug
		dispatch(addRead({ key: "testRooms", type: "runEffect" }));
		// debug

		const memberRoomsRef = db
			.collection("rooms")
			.where(`membersMap.${userID}.profile.name`, ">=", "''");

		const hostRoomsRef = db
			.collection("rooms")
			.where("host.uid", "==", userID)
			.where("roomState", "in", ["available", "private", "full"]);

		const unsub_memberRoomRef = memberRoomsRef.onSnapshot(
			(snaps) => {
				dispatch(resetRoom());
				if (snaps.empty) return;
				// debug
				dispatch(addRead({ key: "testRooms", type: "snap", number: snaps.size }));
				// debug

				snaps.forEach((snap) => {
					const dbRoom = snap.data() as dbRooms;
					const isHost = false;
					const roomID = snap.id;

					const room = dbRoomToRoomMap(dbRoom, isHost, roomID, userID, userProfile);

					// remove others members phone number
					room.members
						.filter((e) => !e.isHost)
						.forEach((e) => {
							e.user.contactMap = { whatsapp: null, telegram: null };
						});

					dispatch(fetchOneRoom(room));
				});
			},
			(error) => {
				if (process.env.NODE_ENV === "development") console.error(error);
				// debug
				dispatch(addRead({ key: "testRooms", type: "error" }));
				// debug
			}
		);

		const unsub_hostRoomRef = hostRoomsRef.onSnapshot(
			(snaps) => {
				if (snaps.empty) return;
				// debug
				dispatch(addRead({ key: "testRooms", type: "snap", number: snaps.size }));
				// debug

				snaps.forEach((snap) => {
					const dbRoom = snap.data() as dbRooms;
					const isHost = true;
					const roomID = snap.id;

					const room = dbRoomToRoomMap(dbRoom, isHost, roomID, userID, userProfile);

					dispatch(fetchOneRoom(room));
				});
			},
			(error) => {
				if (process.env.NODE_ENV === "development") console.error(error);

				// debug
				dispatch(addRead({ key: "testRooms", type: "error" }));
				// debug
			}
		);
		return () => {
			unsub_memberRoomRef();
			unsub_hostRoomRef();
		};
	}, [dispatch, userID, userProfile]);
}

// conversion
const dbRoomToRoomMap = (
	dbRoom: dbRooms,
	isHost: boolean,
	roomID: string,
	userID: string,
	userProfile: Profile
): RoomMap => {
	const {
		service,
		payments,
		roomState,
		max,
		hasMatched,
		membersMap,
		host,
		createdAt,
		countHist,
	} = dbRoom;

	const room: RoomMap = {
		rid: roomID,
		max,
		service,
		roomState,
		hasMatched,
		countHist: countHist as number,

		role: isHost ? "host" : "member",
		paymentsMap: PaymentsToMap(payments),
		startDate: TimestampToString(createdAt),

		members: [
			// add self
			{
				isHost,
				user: {
					uid: userID,
					profile: userProfile,
					contactMap: { telegram: null, whatsapp: null },
				},
				startAt: TimestampToString(isHost ? createdAt : membersMap[userID].startAt),
				me: true,
			},
			// add rest
			...MembersToFamilyProfile(userID, membersMap),
		],

		editing: false,
		expanded: false,
		prevExpand: null,
		prevPaymentsMap: null,
		prevRoomState: null,
		prevMembers: null,
		prevMax: null,
	};

	if (!isHost) {
		// push host

		room.members.splice(1, 0, { isHost: true, user: host, startAt: room.startDate });
	}

	return room;
};
