import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { IUser, dbUser } from "../types";
import { fetchUser, setShowUsernameForm } from "../redux/slices/authSlice";
import { db, fireAuth } from "../configs/firebase";
import { cleanQueue } from "../redux/slices/queueSlice";
import { cleanRooms } from "../redux/slices/roomSlice";
import { addRead } from "../redux/slices/debugSlice";
import { closeDialog } from "../redux/slices/dialogSlice";
import { TimestampToString } from "../utils/Functions";
import { RootState } from "../redux/rootReducer";

export default function useUser() {
	const dispatch = useDispatch();
	const userID = useSelector((state: RootState) => state.auth.firebaseUser?.uid);

	useEffect(() => {
		if (!userID) return;
		// debug
		dispatch(addRead({ key: "user", type: "runEffect" }));
		// debug

		const userRef = db.doc(`/users/${userID}`);

		const unsub = userRef.onSnapshot(
			async (snap) => {
				if (!snap.exists) {
					// new user
					dispatch(setShowUsernameForm(true));
					return;
				}

				// debug
				dispatch(addRead({ key: "user", type: "snap" }));
				// debug

				const _dbUser = snap.data() as dbUser;

				// refresh token
				await fireAuth.currentUser?.getIdToken(true);

				// get customCliams
				const isSubEmail = await fireAuth.currentUser
					?.getIdTokenResult()
					.then((e) => e.claims.isSubEmail);

				const user: IUser = { ...dbUserToIUser(userID, _dbUser), isSubEmail };

				dispatch(fetchUser(user));
				dispatch(cleanQueue(user.using));
				dispatch(cleanRooms(user.using));
				dispatch(closeDialog());
			},
			(error) => {
				// debug;
				if (process.env.NODE_ENV === "development") console.error(error);

				// debug
				dispatch(addRead({ key: "user", type: "error" }));
				// debug
			}
		);

		return () => unsub();
	}, [dispatch, userID]);
}

export function dbUserToIUser(userID: string, dbUser: dbUser): Omit<IUser, "isSubEmail"> {
	return {
		uid: userID,
		commentCount: dbUser.commentCount,
		profile: dbUser.profile,
		dislike: dbUser.dislike || 0,
		like: dbUser.like || 0,
		using: dbUser.using,
		bDay: dbUser.bDay,
		serviceStat: dbUser.serviceStat,
		createdAt: TimestampToString(dbUser.createdAt),
		histRoomCount: dbUser.histRoomCount,
		recordCount: dbUser.recordCount,
	};
}
