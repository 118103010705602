import { fireAuth, cloudFunction, db, Timestamp } from "../configs/firebase";
import { IServices, ProfileID, dbComments, Comments, dbUser, IUser, LikeState } from "../types";
import { TimestampToString } from "../utils/Functions";
import { dbUserToIUser } from "../hooks/useUser";

// report user
//  db.collection("/reports").where("from", "==", from).where("to", "==", to).limit(1);
export type REPORT_USER_PARAM = { from: string; to: string };
export const REPORT_USER = (x: REPORT_USER_PARAM) =>
	cloudFunction.httpsCallable("users-REPORT_USER")(x);

export type SET_SUB_EMAIL_NOTI_PARAM = { key: "isSubEmail"; value: boolean };
export const SET_SUB_EMAIL_NOTI = (x: SET_SUB_EMAIL_NOTI_PARAM) =>
	cloudFunction.httpsCallable("users-SET_CLAIMS")(x);

export type LIKE_DISLIKE_USER_PARAM = {
	targetUserID: string;
	roomID: string;
	histID: string;
	isHost: boolean;
	likeState: LikeState;
};
export const LIKE_DISLIKE_USER = (x: LIKE_DISLIKE_USER_PARAM) =>
	cloudFunction.httpsCallable("users-LIKE_DISLIKE_USER")(x);

export type ADD_COMMENT_PARAM = {
	targetUserID: string;
	isHost: boolean;
	service: IServices;
	author: ProfileID;
	content: string;
	roomID: string;
	histID: string;
};
export const ADD_COMMENT = (x: ADD_COMMENT_PARAM) =>
	cloudFunction.httpsCallable("users-ADD_COMMENT")(x);

export type CREATE_USER_PARAM = {
	uid: string;
	username: string;
	phoneNumber: string;
};
export const CREATE_USER = (x: CREATE_USER_PARAM) =>
	cloudFunction.httpsCallable("users-CREATE_USER")(x);

export async function UPDATE_EMAIL_ADDRESS(data: { email: string }) {
	const fireUser = fireAuth.currentUser;
	if (!fireUser) throw new Error("No such user.");

	const promise1 = fireUser.updateEmail(data.email);
	const promise2 = fireUser.sendEmailVerification();

	await Promise.all([promise1, promise2]);
}

export async function LOGOUT_USER() {
	await fireAuth.signOut();
}

export async function FETCH_COMMENTS(userID: string, lastCreatedAt?: string) {
	let commentsRef = db
		.collection(`/users/${userID}/comments`)
		.orderBy("detail.createdAt", "desc");

	if (!!lastCreatedAt) {
		// next fetch
		commentsRef = commentsRef.startAfter(Timestamp.fromDate(new Date(lastCreatedAt)));
	}

	const snaps = await commentsRef.limit(5).get();
	if (snaps.empty) return [];

	const comments: Comments = snaps.docs.map((snap) => {
		const dbComment: dbComments["detail"] = (snap.data() as dbComments).detail;
		const { service, author, content, createdAt, role } = dbComment;

		return {
			service,
			author,
			content,
			createdAt: TimestampToString(createdAt),
			role,
		};
	});

	return comments;
}

export async function FETCH_USER(userID: string): Promise<Omit<IUser, "isSubEmail"> | null> {
	const userRef = db.doc(`/users/${userID}`);

	const snap = await userRef.get();

	if (!snap.exists) return null;

	const _dbUser = snap.data() as dbUser;

	const user = dbUserToIUser(userID, _dbUser);

	return user;
}
