import { IAppThunk } from "../store";
import { loadThunk, loadingFailed } from "../slices/loadSlice";
import {
	LOGOUT_USER,
	CREATE_USER,
	UPDATE_EMAIL_ADDRESS,
	SET_SUB_EMAIL_NOTI,
	ADD_COMMENT_PARAM,
	ADD_COMMENT,
	REPORT_USER_PARAM,
	SET_SUB_EMAIL_NOTI_PARAM,
	REPORT_USER,
} from "../../api/users";
import { resetAuth } from "../slices/authSlice";
import { resetRoom } from "../slices/roomSlice";
import { resetHistory } from "../slices/historySlice";
import { resetQueue } from "../slices/queueSlice";
import { LoadSnackThunk } from "../slices/snackSlice";
import { analytics, fireAuth } from "../../configs/firebase";

export const logoutUser: IAppThunk = ({ ...rest }) =>
	loadThunk({ ...rest }, async (dispatch) => {
		await LOGOUT_USER();
		dispatch(resetAuth());
		dispatch(resetRoom());
		dispatch(resetQueue());
		dispatch(resetHistory());
	});

export const signUpUser: IAppThunk<{ data: { username: string; email: string } }> = ({
	data,
	...rest
}) =>
	loadThunk({ ...rest }, async (dispatch) => {
		try {
			const fireUser = fireAuth.currentUser;
			if (!fireUser || !fireUser.phoneNumber) throw new Error("No such user.");

			await fireUser.updateEmail(data.email);
			await fireUser.updateProfile({ displayName: data.username });
			await SET_SUB_EMAIL_NOTI({ key: "isSubEmail", value: true });

			await CREATE_USER({
				uid: fireUser.uid,
				username: data.username,
				phoneNumber: fireUser.phoneNumber,
			});

			analytics.logEvent("sign_up", { method: "phone" });

			await fireUser.sendEmailVerification();
		} catch (error) {
			switch (error.code) {
				case "auth/email-already-in-use":
					dispatch(loadingFailed(error.message));

					break;

				case "auth/requires-recent-login":
					dispatch(loadingFailed(error.message));
					dispatch(logoutUser({}));
					break;

				default:
					dispatch(loadingFailed("Sorry, something went wrong."));
					if (process.env.NODE_ENV === "development") console.error(error);
					break;
			}
		}
	});

export const updateEmailAddr: IAppThunk<{ data: { email: string } }> = ({ data, ...rest }) =>
	loadThunk({ ...rest }, async (dispatch) => {
		try {
			analytics.logEvent("update_email");
			await UPDATE_EMAIL_ADDRESS({ email: data.email });
		} catch (error) {
			dispatch(loadingFailed(error.message));
			if (process.env.NODE_ENV === "development") console.error(error);
		}
	});

export const setSubEmailNoti: IAppThunk<SET_SUB_EMAIL_NOTI_PARAM> = ({ key, value, ...rest }) =>
	LoadSnackThunk({ ...rest }, async (dispatch) => {
		analytics.logEvent("setSubEmailNoti", { value });
		await SET_SUB_EMAIL_NOTI({ key, value });
	});

export const reportUser: IAppThunk<REPORT_USER_PARAM> = ({ from, to, ...rest }) =>
	LoadSnackThunk({ ...rest }, async (dispatch) => {
		// analytics.logEvent("setSubEmailNoti", { value });
		// await SET_SUB_EMAIL_NOTI({ key, value });

		await REPORT_USER({ from, to });
	});

export const addCommentThunk: IAppThunk<ADD_COMMENT_PARAM> = ({
	targetUserID,
	isHost,
	service,
	author,
	content,
	roomID,
	histID,
	...rest
}) =>
	loadThunk({ ...rest }, async (dispatch) => {
		try {
			analytics.logEvent("add_comment", { to: targetUserID });

			await ADD_COMMENT({ targetUserID, isHost, service, author, content, roomID, histID });
		} catch (error) {
			dispatch(loadingFailed(error.message));
			if (process.env.NODE_ENV === "development") console.error(error);
		}
	});
