import { AppThunk, IAppThunk } from "../store";
import { loadingFailed, loadThunk } from "../slices/loadSlice";
import { cloudFunction, db } from "../../configs/firebase";
import { dbQueueStat, dbRoomStat, IServices, dbOneRoomStat, dbCount } from "../../types";
import { addAppStat } from "../slices/debugSlice";
import { fetchAppStat, initialFetchRoomList, setRoomListLoading } from "../slices/appSlice";
import { TimestampToString } from "../../utils/Functions";

async function FETCH_APP_STAT() {
	const queueStat = (await db.doc("/stat/queue").get()).data() as dbQueueStat;
	const roomStat = (await db.doc("/stat/room").get()).data() as dbRoomStat;

	return { queueStat, roomStat };
}

export const fetchApp: IAppThunk = ({ ...rest }) =>
	loadThunk({ ...rest }, async (dispatch) => {
		// debug
		dispatch(addAppStat());
		// debug

		const { roomStat, queueStat } = await FETCH_APP_STAT();

		dispatch(
			fetchAppStat({
				queueStat: Object.fromEntries(
					Object.entries(queueStat || {}).map(([service, dbCount]) => [
						service,
						{ ...dbCount, lastUpdate: TimestampToString(dbCount.lastUpdate) },
					])
				) as Record<IServices, dbCount & { lastUpdate: string }>,

				roomStat: Object.fromEntries(
					Object.entries(roomStat || {}).map(([service, dbOneRoomStat]) => [
						service,
						{
							...dbOneRoomStat,
							lastUpdate: TimestampToString(dbOneRoomStat.lastUpdate),
						},
					])
				) as Record<IServices, dbOneRoomStat & { lastUpdate: string }>,
			})
		);
	});

type FETCH_AVA_ROOM_PREVIEW_PARAM = { lastCreatedAt: number | null };
export const FETCH_AVA_ROOM_PREVIEW = (x: FETCH_AVA_ROOM_PREVIEW_PARAM) =>
	cloudFunction.httpsCallable("apiRoomV1-FETCH_AVA_ROOM_PREVIEW")(x);

// first fetch
export const firstFetchRoomList = (x: FETCH_AVA_ROOM_PREVIEW_PARAM): AppThunk => async (
	dispatch
) => {
	try {
		dispatch(setRoomListLoading("pending"));

		const res = await FETCH_AVA_ROOM_PREVIEW(x);

		// throw new Error("Shit error");

		dispatch(setRoomListLoading("succeeded"));

		// console.log("🔥  data", res.data.data);

		dispatch(initialFetchRoomList(res.data.data));
	} catch (error) {
		dispatch(setRoomListLoading("failed"));
		dispatch(loadingFailed("Sorry, something went wrong :("));
		console.error(error);
	}
};
