import React, { FC } from "react";
import Spinner from "../components/Feedback/Spinner";
import ErrorPaper from "../components/Feedback/ErrorPaper";
import CustomSnack from "../components/Feedback/CustomSnack";
import AlertPaper from "../components/Dialogs/AlertWarning";
import { useTheme, Backdrop } from "@material-ui/core";
import FormSignUp from "../pages/Auth/components/FormSignUp";
import { useSelector } from "react-redux";
import { RootState } from "../redux/rootReducer";

const StateComponent: FC = () => {
	const theme = useTheme();

	const showUsernameForm = useSelector((state: RootState) => state.auth.showUsernameForm);
	return (
		<>
			<Spinner />
			<ErrorPaper />
			<CustomSnack />
			<AlertPaper />

			<Backdrop style={{ zIndex: theme.zIndex.drawer }} open={showUsernameForm}>
				<FormSignUp />
			</Backdrop>
		</>
	);
};

export default StateComponent;
