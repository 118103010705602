import React, { FC, useState, useRef, useEffect } from "react";
import {
	Popper,
	Grow,
	Paper,
	ClickAwayListener,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Avatar,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/rootReducer";

import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useHistory } from "react-router-dom";

import LogoutIcon from "@material-ui/icons/ExitToApp";
// import AccessibilityIcon from "@material-ui/icons/Accessibility";
import LoginButton from "./LoginButton";
import { logoutUser } from "../../redux/thunks/authThunk";
import { AppRoutes } from "../../navigation/AppRoutes";

import { useTranslation } from "react-i18next";

type MenuRows = {
	icon: JSX.Element;
	text: string;
	fn: (e?: any) => void;
};

const UserPoper: FC = () => {
	const { t } = useTranslation();

	const [openMenu, setOpenMenu] = useState(false);
	const anchorRef = React.useRef<HTMLButtonElement>(null);

	const user = useSelector((state: RootState) => state.auth.user);

	// Popper
	const handleToggleMenu = () => {
		setOpenMenu((prevOpen) => !prevOpen);
	};

	const handleCloseMenu = (event: React.MouseEvent<EventTarget>) => {
		if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
			return;
		}

		setOpenMenu(false);
	};

	function handleListKeyDown(event: React.KeyboardEvent) {
		if (event.key === "Tab") {
			event.preventDefault();
			setOpenMenu(false);
		}
	}

	// return focus to the button when we transitioned from !open -> open
	const prevOpen = useRef(openMenu);
	useEffect(() => {
		if (prevOpen.current === true && openMenu === false) {
			anchorRef.current!.focus();
		}

		prevOpen.current = openMenu;
	}, [openMenu]);

	const history = useHistory();
	// const classes = useStyles();
	const dispatch = useDispatch();

	const handleLogout = () => {
		dispatch(logoutUser({}));
	};

	// const handleHelp = () => {};

	const Rows: MenuRows[] = [
		// {
		// 	text: "Help",
		// 	icon: <AccessibilityIcon />,
		// 	fn: handleHelp,
		// },
		{
			text: t("Logout"),
			icon: <LogoutIcon />,
			fn: handleLogout,
		},
	];

	return user ? (
		<>
			<IconButton
				disableRipple
				aria-label='profile button'
				color='inherit'
				ref={anchorRef}
				aria-controls={openMenu ? "menu-list-grow" : undefined}
				aria-haspopup='true'
				onClick={() => history.push(AppRoutes.PROFILE)}
			>
				<Avatar alt={user.profile.name} src={user.profile.photo || "no photo"} />
			</IconButton>

			<IconButton
				disableRipple
				aria-label='more'
				color='inherit'
				ref={anchorRef}
				aria-controls={openMenu ? "menu-list-grow" : undefined}
				aria-haspopup='true'
				onClick={handleToggleMenu}
			>
				<MoreVertIcon />
			</IconButton>
			<Popper
				open={openMenu}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
			>
				<Grow>
					<ClickAwayListener onClickAway={handleCloseMenu}>
						<Paper elevation={5}>
							<List
								onKeyDown={handleListKeyDown}
								component='nav'
								aria-label='help logout'
							>
								{Rows.map((e) => (
									<ListItem
										key={e.text + "-user-popper-button"}
										onClick={(event) => {
											e.fn();
											handleCloseMenu(event);
										}}
										button
									>
										<ListItemIcon>
											{React.cloneElement(e.icon, { fontSize: "small" })}
										</ListItemIcon>
										<ListItemText primary={e.text} />
									</ListItem>
								))}
							</List>
						</Paper>
					</ClickAwayListener>
				</Grow>
			</Popper>
		</>
	) : (
		<>
			<LoginButton />
			<div style={{ marginRight: 18 }} />
		</>
	);
};

export default UserPoper;
