import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";

import Loading from "../components/Loading";
import { AppRoutes } from "./AppRoutes";

const AppRouter = () => {
	return (
		<Suspense fallback={<Loading />}>
			<Switch>
				<Route
					exact
					path={AppRoutes.HEAD}
					component={React.lazy(() => import("../pages/welcome"))}
				/>

				<Route
					exact
					path={AppRoutes.HOME}
					component={React.lazy(() => import("../pages/home"))}
				/>

				<Route
					exact
					path={AppRoutes.FAMILY + "/:service"}
					component={React.lazy(() => import("../pages/family"))}
				/>

				<Route
					exact
					path={AppRoutes.FAMILY}
					component={React.lazy(() => import("../pages/family"))}
				/>

				<Route
					exact
					path={AppRoutes.PROFILE}
					component={React.lazy(() => import("../pages/profile"))}
				/>

				<Route
					exact
					path={AppRoutes.USERS}
					component={React.lazy(() => import("../pages/users"))}
				/>

				<Route
					exact
					path={AppRoutes.HISTORY}
					component={React.lazy(() => import("../pages/history"))}
				/>

				<Route
					exact
					path={AppRoutes.SETTINGS}
					component={React.lazy(() => import("../pages/settings"))}
				/>

				<Route
					exact
					path={AppRoutes.TERMS_OF_SERVICE}
					component={React.lazy(
						() => import("../pages/settings/components/TermsOfService")
					)}
				/>

				<Route
					exact
					path={AppRoutes.PRIVACY_POLICY}
					component={React.lazy(
						() => import("../pages/settings/components/PrivacyPolicy")
					)}
				/>

				{process.env.NODE_ENV === "development" && (
					<>
						<Route
							exact
							path={AppRoutes.DEBUG}
							component={React.lazy(() => import("../Debug"))}
						/>
						<Route
							exact
							path={"/playground"}
							component={React.lazy(() => import("../components/AdminTable"))}
						/>
						<Route
							exact
							path={"/roomlists"}
							component={React.lazy(
								() => import("../pages/home/components/RoomLists")
							)}
						/>
					</>
				)}
			</Switch>
		</Suspense>
	);
};

export default AppRouter;
