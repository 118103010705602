import React, { FC } from "react";
import { Button, Dialog, DialogContent, DialogContentText, DialogActions } from "@material-ui/core";
import ErrorIcon from "@material-ui/icons/Error";

import { useDispatch, useSelector } from "react-redux";
import { resetLoading, closeDialog } from "../../redux/slices/loadSlice";
import { RootState } from "../../redux/rootReducer";

const ErrorPaper: FC = () => {
	const { error, errorMsg } = useSelector((state: RootState) => state.load);

	const dispatch = useDispatch();
	function handleClose() {
		dispatch(closeDialog());

		setTimeout(() => {
			dispatch(resetLoading());
		}, 500);
	}

	return (
		<Dialog
			open={error}
			PaperProps={{
				style: {
					borderRadius: 15,
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				},
			}}
		>
			<DialogContent>
				<ErrorIcon color='secondary' fontSize='large' />
			</DialogContent>

			<DialogContent>
				<DialogContentText align='center' color='textPrimary'>
					{JSON.stringify(errorMsg)}
				</DialogContentText>
			</DialogContent>

			<DialogActions style={{ width: "100%" }}>
				<Button fullWidth onClick={handleClose} color='secondary'>
					CLOSE
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ErrorPaper;
