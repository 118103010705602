import { RoomMemberMap, FamilyProfile, IPayments } from "../types";
import { PaymentOptions } from "../constants/PaymentOptions";

export const delay = (t: number) => new Promise((resolve) => setTimeout(resolve, t));

export function MapFieldToValue(input: any, value: any): any {
	let temp = { ...input };

	Object.entries(temp).forEach(([key]) => {
		temp[key] = value;
	});

	return temp;
}

// Sequence generator function (commonly referred to as "range", e.g. Clojure, PHP etc)
export const range = (start: number, stop: number, step: number): number[] =>
	Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

// old android++
// export const NoniOSvibrate = () => {
// 	if ([isIOS, isSafari, isOpera, isIE].every((e) => e === false)) window.navigator.vibrate(200);
// };

export const TimestampToString = (
	x: firebase.firestore.FieldValue | firebase.firestore.Timestamp
) => (x as firebase.firestore.Timestamp).toDate().toISOString();

export const MembersToFamilyProfile = (
	userID: string,
	membersMap: Record<string, RoomMemberMap>
): FamilyProfile[] =>
	Object.entries(membersMap)
		.filter(([k]) => k !== userID)
		.map(([key, { contactMap, profile, startAt }]) => ({
			isHost: false,
			user: { uid: key, profile, contactMap },
			startAt: TimestampToString(startAt),
		}));

export const PaymentsToMap = (payments: IPayments[]): Record<IPayments, boolean> =>
	Object.fromEntries(PaymentOptions.map((key) => [key, payments.includes(key)])) as Record<
		IPayments,
		boolean
	>;
