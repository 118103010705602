import React, { FC } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Paper, Dialog } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			'& > * + *': {
				marginLeft: theme.spacing(2),
			},
		},
		paper: {
			width: 100,
			height: 100,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			borderRadius: 15,
			flexDirection: 'column',
		},
	})
);

const Spinner: FC = () => {
	const classes = useStyles();
	const { loading } = useSelector((state: RootState) => state.load);

	return (
		<Dialog
			PaperProps={{ style: { backgroundColor: 'transparent', boxShadow: 'none' } }}
			open={loading}
		>
			<div className={classes.root}>
				<Paper className={classes.paper}>
					<CircularProgress />
				</Paper>
			</div>
		</Dialog>
	);
};

export default Spinner;
