import React, { useState, Suspense } from "react";
import Button from "@material-ui/core/Button/Button";
import Loading from "../Loading";
import { useTranslation } from "react-i18next";

const LoginDialog = React.lazy(() => import("../../pages/home/components/LoginDialog"));

const LoginButton = () => {
	const { t } = useTranslation();

	const [open, setOpen] = useState(false);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<Suspense fallback={<Loading />}>
			{open && <LoginDialog open={open} onClose={handleClose} />}
			<Button
				size='large'
				disableRipple
				variant='contained'
				disableElevation
				color='primary'
				onClick={handleOpen}
			>
				{t("Login")}
			</Button>
		</Suspense>
	);
};

export default LoginButton;
