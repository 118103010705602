import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type DialogState = {
	open: boolean;
	title: string;
	description: string;
	confirmText?: string;
	cancelText?: string;
	onConfirm?: () => void;
	onCancel?: () => void;
};

const initialState: DialogState = {
	open: false,
	title: "Dialog title",
	description: "Dialog content",
	confirmText: "Confirm",
	cancelText: "Cancel",
};

const dialogSlice = createSlice({
	name: "dialog",
	initialState: initialState,
	reducers: {
		closeDialog(dialog) {
			dialog.open = false;
			dialog.onCancel = undefined;
			dialog.onConfirm = undefined;
		},

		openDialog(dialog, action: PayloadAction<Omit<DialogState, "open">>) {
			dialog.open = true;
			dialog.title = action.payload.title;
			dialog.description = action.payload.description;

			dialog.confirmText = action.payload.confirmText || "Confirm";
			dialog.cancelText = action.payload.cancelText || "Cancel";

			dialog.onConfirm = action.payload.onConfirm;
			dialog.onCancel = action.payload.onCancel;
		},
	},
});

export const { closeDialog, openDialog } = dialogSlice.actions;
export default dialogSlice.reducer;
