import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoadThunk } from "../store";

export type LoadingState = {
	loading: boolean;
	error: boolean;
	errorMsg: string;
};

const initialState: LoadingState = {
	loading: false,
	error: false,
	errorMsg: "",
};

const loadSlice = createSlice({
	name: "load",
	initialState: initialState,
	reducers: {
		startLoading(load) {
			if (!load.loading) load.loading = true;
		},

		stopLoading(load) {
			if (load.loading) load.loading = false;
		},

		loadingFailed(load, action: PayloadAction<string>) {
			load.loading = false;
			load.error = true;
			load.errorMsg = action.payload;
		},
		closeDialog(load) {
			load.error = false;
		},

		resetLoading() {
			return initialState;
		},
	},
});

export const {
	loadingFailed,
	stopLoading,
	resetLoading,
	startLoading,
	closeDialog,
} = loadSlice.actions;
export default loadSlice.reducer;

export const loadThunk: LoadThunk = ({ onSuccess, onError, onFinish }, action) => async (
	dispatch
) => {
	// start oading
	dispatch(startLoading());
	await action(dispatch)
		.then(() => {
			// success
			dispatch(stopLoading());
			if (!!onSuccess) onSuccess();
		})
		.catch((error) => {
			// fail
			console.error(error);

			dispatch(stopLoading());
			if (!!onError) onError();
		})
		.finally(() => {
			if (!!onFinish) onFinish();
		});
};
