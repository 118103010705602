import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { fireAuth, analytics } from "../configs/firebase";
import { fetchFireAuth } from "../redux/slices/authSlice";
import { resetJoinPaperState } from "../redux/slices/appSlice";

export default function useFireAuth() {
	const dispatch = useDispatch();
	useEffect(() => {
		const unsub = fireAuth.onAuthStateChanged(async (user) => {
			if (user) {
				dispatch(resetJoinPaperState());
				// User is signed in
				const firebaseUser = user.toJSON() as firebase.User;
				dispatch(fetchFireAuth(firebaseUser));

				analytics.setUserId(user.uid);
				analytics.logEvent("login", { method: "phone" });
			} else {
				// No user

				if (process.env.NODE_ENV === "development") console.log("No user");
			}
		});
		return () => unsub();
	}, [dispatch]);
}
