import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchQueueOnce } from '../redux/thunks/queueThunk';
import { RootState } from '../redux/rootReducer';

export default function useQueueOnce() {
	const dispatch = useDispatch();
	const userID = useSelector((state: RootState) => state.auth.user?.uid);
	const using = useSelector((state: RootState) => state.auth.user?.using) || false;

	useEffect(() => {
		if (!userID || !Object.entries(using).filter(([k, v]) => v?.state === 'queue').length)
			return;

		dispatch(fetchQueueOnce({ userID }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch, userID]);
}
