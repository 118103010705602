import { IServices } from "../types";

export const AppServices: IServices[] = [
	"Netflix",
	"Nintendo Switch",
	"Spotify",
	"YouTube Premium",
	"iCloud",
	"Google Drive",
];

export const ServiceNumbers: {
	[x in IServices]: { max: number; price: number };
} = {
	Netflix: {
		max: 4,
		price: 93,
	},
	"Nintendo Switch": {
		max: 8,
		price: 24.58333,
	},
	Spotify: {
		max: 6,
		price: 88,
	},
	"YouTube Premium": {
		max: 6,
		price: 98,
	},
	iCloud: {
		max: 6,
		price: 78,
	},
	"Google Drive": {
		max: 6,
		price: 79,
	},
};
